/**
 * เรียกข้อมูลการตั้งค่าจาก server
 */

import 'regenerator-runtime/runtime'

import api from "@/services/apiService"
import Encrypt from "@/helpers/encrypt"

class SystemConfigService {

  /**
   * ดึงข้อมูลกลุ่มทั้งหมดในระบบ
   * @return {Object}
   */
  getGroups() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/group')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลอัตราจ่าย
   * @return {Object}
   */
  // getPayRates() {
  //   return new Promise((resolve, reject) => {
  //     api.get(process.env.VUE_APP_BACKEND_API + 'config/payrate')
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       reject(error)
  //     });
  //   });
  // }

  /**
   * ดึงข้อมูลรายการหวยในระบบ
   * @return {Object}
   */
  getMarkets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

}

export default new SystemConfigService()
