<template>
  <span class="font-weight-normal" :class="statusClass">{{statusText}}</span>
</template>
<script>
export default {
  name: 'NumberStatus',
  props: ['status'],
  computed: {
    /**
     * Failed ไม่รับแทง
     * Accepted รับแทง
     * Canceled ยกเลิก
     * Refund คืนเงิน
     * Processing กำลังออกผล
     * Paying กำลังจ่ายเงิน
     * Completed จ่ายเงินแล้ว
     */
    statusClass() {
      return {
        Failed: 'text-warning',
        Accepted: 'text-success',
        Canceled: 'text-danger',
        Refund: 'text-warning',
        Processing: 'text-info',
        Paying: 'text-success',
        Completed: 'text-primary'
      }[this.status]
    },
    statusText() {
      return {
        Failed: 'ไม่รับแทง',
        Accepted: 'รับแทง',
        Canceled: 'ยกเลิก',
        Refund: 'คืนเงิน',
        Processing: 'กำลังออกผล',
        Paying: 'กำลังจ่ายเงิน',
        Completed: 'จ่ายเงินแล้ว'
      }[this.status]
    }
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 90% !important;
}
</style>
